import { Routes } from "@angular/router";
import { authGuard } from "./guards/auth.guard";
import { hasRoles } from "./guards/hasRoles.guard";
import { UserRole } from "./models/user";
// import { redirectManager } from "./guards/redirectManager.guard";

export const routes: Routes = [
    {
        path: "",
        loadComponent: () =>
            import("./components/layout/layout.component").then(
                (m) => m.LayoutComponent
            ),
        canActivate: [authGuard],
        children: [
            {
                path: "",
                pathMatch: "full",
                redirectTo: "mrktg-plan-annual"
            },
            {
                path: "redirect",
                pathMatch: "full",
                redirectTo: "mrktg-plan-annual"
            },
            {
                path: "mrktg-plan-annual",
                canActivate: [],
                children: [
                    {
                        path: "",
                        // canActivate: [redirectManager],
                        loadComponent: () =>
                            import(
                                "./pages/mrktg-plan-annual/mrktg-plan-annual.component"
                            ).then((m) => m.MrktgPlanAnnualComponent)
                    },
                    {
                        path: ":dealerId",
                        loadComponent: () =>
                            import(
                                "./pages/mrktg-plan-annual/mrktg-plan-annual.component"
                            ).then((m) => m.MrktgPlanAnnualComponent)
                    }
                ]
            },
            {
                path: "mrktg-plan-quarterly",
                // canActivate: [hasRoles],
                // data: {
                //     hasRoles: [UserRole.DEALER_OPERATOR, UserRole.DEALER_READER]
                // },
                loadComponent: () =>
                    import(
                        "./pages/mrktg-plan-quarterly/mrktg-plan-quarterly.component"
                    ).then((m) => m.MrktgPlanQuarterlyComponent)
            },
            {
                path: "mrktg-plan-investments",
                canActivate: [],
                children: [
                    {
                        path: "",
                        // canActivate: [redirectManager],
                        loadComponent: () =>
                            import(
                                "./pages/mrktg-plan-investments/mrktg-plan-investments.component"
                            ).then((m) => m.MrktgPlanInvestmentsComponent),
                        children: [
                            {
                                path: "",
                                redirectTo: "quarter-overview",
                                pathMatch: "full"
                            },
                            {
                                path: "quarter-overview",
                                loadComponent: () =>
                                    import(
                                        "./pages/mrktg-plan-investments/quarter-overview/quarter-overview.component"
                                    ).then((m) => m.QuarterOverviewComponent)
                            },
                            {
                                path: "channel-overview",
                                loadComponent: () =>
                                    import(
                                        "./pages/mrktg-plan-investments/channel-overview/channel-overview.component"
                                    ).then((m) => m.ChannelOverviewComponent)
                            }
                        ]
                    },
                    {
                        path: ":dealerId",
                        loadComponent: () =>
                            import(
                                "./pages/mrktg-plan-investments/mrktg-plan-investments.component"
                            ).then((m) => m.MrktgPlanInvestmentsComponent),
                        children: [
                            {
                                path: "",
                                redirectTo: "quarter-overview",
                                pathMatch: "full"
                            },
                            {
                                path: "quarter-overview",
                                loadComponent: () =>
                                    import(
                                        "./pages/mrktg-plan-investments/quarter-overview/quarter-overview.component"
                                    ).then((m) => m.QuarterOverviewComponent)
                            },
                            {
                                path: "channel-overview",
                                loadComponent: () =>
                                    import(
                                        "./pages/mrktg-plan-investments/channel-overview/channel-overview.component"
                                    ).then((m) => m.ChannelOverviewComponent)
                            }
                        ]
                    }
                ]
            },
            {
                path: "campaigns",
                canActivate: [],
                children: [
                    {
                        path: "",
                        // canActivate: [redirectManager],
                        // data: {
                        //     redirectManager: [
                        //         UserRole.ADMIN,
                        //         UserRole.HQ,
                        //         UserRole.MARKENTING_MANAGER
                        //     ]
                        // },
                        loadComponent: () =>
                            import(
                                "./pages/campaigns/campaigns.component"
                            ).then((m) => m.CampaignsComponent)
                    },
                    {
                        path: "detail",
                        canActivate: [],
                        children: [
                            {
                                path: "",
                                loadComponent: () =>
                                    import(
                                        "./pages/campaign-detail/campaign-detail.component"
                                    ).then((m) => m.CampaignDetailComponent)
                            },
                            {
                                path: ":id",
                                loadComponent: () =>
                                    import(
                                        "./pages/campaign-detail/campaign-detail.component"
                                    ).then((m) => m.CampaignDetailComponent)
                            }
                        ]
                    }
                ]
            },
            {
                path: "dealers",
                canActivate: [hasRoles],
                data: {
                    hasRoles: [
                        UserRole.ADMIN,
                        UserRole.HQ,
                        UserRole.MARKENTING_MANAGER,
                        UserRole.SUPER_MARKET_MANAGER
                    ]
                },
                children: [
                    {
                        path: "",
                        loadComponent: () =>
                            import("./pages/dealers/dealers.component").then(
                                (m) => m.DealersComponent
                            )
                    },
                    {
                        path: ":id",
                        loadComponent: () =>
                            import(
                                "./pages/dealer-detail/dealer-detail.component"
                            ).then((m) => m.DealerDetailComponent)
                    }
                ]
            },
            {
                path: "notifications",
                canActivate: [],
                loadComponent: () =>
                    import(
                        "./pages/notifications/notifications.component"
                    ).then((m) => m.NotificationsComponent)
            },
            {
                path: "metrics",
                canActivate: [],
                loadComponent: () =>
                    import("./pages/metrics/metrics.component").then(
                        (m) => m.MetricsComponent
                    ),
                children: [
                    {
                        path: "",
                        redirectTo: "investments-per-channel", //TODO: change to investments-per-channel when chart is ready
                        pathMatch: "full"
                    },
                    {
                        path: "leads-and-sales-takeaways",
                        loadComponent: () =>
                            import(
                                "./pages/metrics/components/charts/leads-and-sales-takeaways/leads-and-sales-takeaways.component"
                            ).then((m) => m.LeadsAndSalesTakeawaysComponent)
                        // data: { shouldReuseKey: "leads-and-sales-takeaways" }
                    },
                    {
                        path: "investments-per-channel",
                        loadComponent: () =>
                            import(
                                "./pages/metrics/components/charts/investments-per-channel/investments-per-channel.component"
                            ).then((m) => m.InvestmentsPerChannelComponent)
                    },
                    {
                        path: "spending-per-carline",
                        loadComponent: () =>
                            import(
                                "./pages/metrics/components/charts/spending-per-carline/spending-per-carline.component"
                            ).then((m) => m.SpendingPerCarlineComponent)
                    },

                    {
                        path: "target-vs-budget-vs-actual",
                        loadComponent: () =>
                            import(
                                "./pages/metrics/components/charts/target-vs-budget-vs-actual/target-vs-budget-vs-actual.component"
                            ).then((m) => m.TargetVsBudgetVsActualComponent)
                    },

                    {
                        path: "campaign-status",
                        loadComponent: () =>
                            import(
                                "./pages/metrics/components/charts/campaign-status/campaign-status.component"
                            ).then((m) => m.CampaignStatusComponent)
                    }
                ]
            }
        ]
    },
    {
        path: "unauthorized",
        loadComponent: () =>
            import("./pages/unauthorized/unauthorized.component").then(
                (m) => m.UnauthorizedComponent
            )
    },
    {
        path: "**",
        loadComponent: () =>
            import("./pages/notFound/notFound.component").then(
                (m) => m.NotFoundComponent
            )
    }
];
